import React from 'react';
import Select from 'components/form/select';
import { withField } from 'components/form/with-field';
import { useAuth } from 'providers/auth';
import SelectWrapper from 'components/headless-select';

const CONFIGURATION = [
  { value: '1', label: 'Doors' },
  { value: '0', label: 'Aperio' },
  { value: '3', label: 'Elevator Control' }
];

const CONFIGURATION_A1210 = [
  { value: '1', label: 'Doors' },
  { value: '3', label: 'Elevator Control' }
];
const ConfigurationSelect = (props) => {
  const {
    field: { name },
    form: { values, setFieldValue }
  } = props;

  const { user } = useAuth();

  const options =
    values.product != 'A1210'
      ? CONFIGURATION.filter(
          (o) =>
            o.value != '3' ||
            user.features?.elevators == 'on' ||
            user.features?.elevators
        )
      : CONFIGURATION_A1210;

  React.useEffect(() => {
    if (values.product == 'A1210' && values.doors != '1')
      setFieldValue('doors', '1');
  }, [values?.product]);

  return (
    <SelectWrapper
      title="Configuration"
      simple
      isSearchable={false}
      isMulti={false}
      options={options}
      idx="value"
      label="label"
      value={options.find((r) => r.value == values.doors)}
      closeMenuOnSelect={true}
      onChange={(e) => {
        props.form?.setFieldValue(props.field?.name, e?.value ?? null);
      }}
      {...props}
    />
  );
};

export default ConfigurationSelect;
