import React from 'react';
import Select from 'components/form/select';
import { components } from 'react-select';
import { css } from '@emotion/react';
import EVENTS_CONSTANTS from 'constants/events';
/** @jsxImportSource @emotion/react **/
import styled from '@emotion/styled';
import useFormValues from 'hooks/form-values-hook';
import SelectWrapper from './headless-select';
import { CaretDown, CaretDownFill } from 'react-bootstrap-icons';

const Group = (props) => {
  const [isOpen, setIsOpen] = React.useState();

  React.useEffect(
    () => setIsOpen(props.selectProps.inputValue ? true : false),
    [props.selectProps.inputValue]
  );
  //const hasSelectedOption = props.children.some((opt) => opt.props.isSelected);

  const onClick = () => setIsOpen((prev) => !prev);
  return (
    <components.Group
      {...props}
      headingProps={{ ...props.headingProps, onClick, isOpen }}
      children={isOpen ? props.children : null}
    />
  );
};

const GroupHeading = ({ ...props }) => {
  return (
    <div
      data-open={props.isOpen}
      onClick={props.onClick}
      css={css`
        cursor: pointer;
        padding: 0.8rem 0;
        &[data-open='true'] {
          svg {
            transform: rotate(180deg);
          }
        }
        display: flex;
        align-items: center;
        width: 100%;
        svg {
          transition: 300ms ease all;
          margin-left: auto;
          margin-right: 0.5rem;
        }
        &:hover {
          background: var(--neutral-05);
        }
      `}
    >
      <components.GroupHeading
        {...props}
        css={css`
          pointer-events: none;
        `}
      />
      <CaretDownFill />
    </div>
  );
};

export const EVENTS = [
  {
    id: 'reason',
    label: 'Reason',
    options: [
      {
        value: 'InvalidAccessProfile',
        label: 'Invalid Access Level',
        reason: true
      },

      {
        value: 'CredentialNotFound',
        label: 'Unfamiliar Credential',
        reason: true
      },
      {
        value: 'CredentialNotEnabled',
        label: 'Credential Disabled',
        reason: true
      },
      // {
      //   value: 'InvalidAccessProfile',
      //   label: 'No Access Granted From Device',
      //   reason: true
      // },
      {
        value: 'Unauthorized',
        label: 'Credential Not Authorized',
        reason: true
      }
    ]
  },
  {
    id: 'level',
    label: 'Level',
    options: [
      {
        value: 'info',
        label: 'Level [INFO]',
        level: 'info'
      },
      {
        value: 'warning',
        label: 'Level [WARNING]',
        level: 'warning'
      },
      {
        value: 'critical',
        label: 'Level [CRITICAL]',
        level: 'critical'
      }
    ]
  },

  {
    id: 'category',
    label: 'category',
    options: [
      {
        value: 'access',
        label: 'All Access Events',
        description: 'Includes all door access events, excluding alarams',
        category: 'access'
      },
      {
        value: 'device',
        label: 'All Device Events',
        category: 'device',
        description:
          'Includes all device specific events. eg: online, offline..'
      },
      {
        value: 'alarm',
        label: 'All Alarm Events',
        description: 'Includes door alarm events',
        category: 'alarm'
      }
    ]
  },

  {
    value: 'manualUnlockDoor',
    label: 'Operator Unlock door',
    type: true
  },
  { value: 'manualLockDoor', label: 'Operator Lock door', type: true },
  {
    value: 'manualAccessDoor',
    label: 'Operator Access Door',
    type: true
  },
  {
    value: 'accessDenied',
    label: 'Access Denied',
    type: true
  },
  { value: 'accessGranted', label: 'Access Granted', type: true },
  { value: 'rexOpenDoor', label: 'REX Open Door', type: true },
  { value: 'offline', label: 'Device Offline', type: true },
  { value: 'online', label: 'Device Online', type: true },
  { value: 'doorAccessed', label: 'Door Accessed', type: true },
  { value: 'doorUnlocked', label: 'Door Unlocked', type: true },
  { value: 'doorLocked', label: 'Door Locked', type: true },
  { value: 'forcedOpen', label: 'Door Forced Open', type: true },
  { value: 'deniedLimit', label: 'Denied Limit', type: true },
  { value: 'openTooLong', label: 'Door Open Too Long', type: true },
  { value: 'accessNotTaken', label: 'Access Not Taken', type: true },
  { value: 'siteLocked', label: 'Site Locked', type: true },
  { value: 'siteReleased', label: 'Site Released', type: true },
  { value: 'ioPort', label: 'IO Port', type: true },
  { value: 'ioOpenTooLong', label: 'IO Port OTL', type: true },

  { value: 'scheduleOn', label: 'Schedule On', type: true },
  { value: 'scheduleOff', label: 'Schedule Off', type: true },

  { value: 'licensePlateGranted', label: 'License Plate Granted', type: true },
  { value: 'licensePlateDenied', label: 'License Plate Denied', type: true },
  {
    value: 'licensePlateUnfamiliar',
    label: 'License Plate Unfamiliar',
    type: true
  },
  { value: 'restoreMissingEvents', label: 'Events Restore', type: true },
  { value: 'restoreStart', label: 'Restore Start', type: true },
  { value: 'restoreFinish', label: 'Restore Finish', type: true },
  { value: 'restoreError', label: 'Restore Error', type: true },
  { value: 'systemReady', label: 'System Ready', type: true },
  { value: 'batteryAlarm', label: 'Aperio Battery Alarm', type: true },
  { value: 'callStarted', label: 'Call Started', type: true },
  { value: 'callAnswered', label: 'Call Answered', type: true },
  { value: 'callMissed', label: 'Call Missed', type: true },

  { value: 'sensorOnline', label: 'Sensor Online', type: true },
  { value: 'sensorOffline', label: 'Sensor Offline', type: true },

  { value: 'sensorAlertOn', label: 'Sensor Alert On', type: true },
  { value: 'sensorAlertOff', label: 'Sensor Alert Off', type: true },
  { value: 'storageDisruptionOn', label: 'Storage Disruption', type: true },
  { value: 'storageDisruptionOff', label: 'Storage OK', type: true },
  { value: 'tamperingOn', label: 'Device Tampering', type: true },
  { value: 'criticalTemperature', label: 'Critical Temperature', type: true },
  { value: 'cloudActionRuleStart', label: 'Cloud Rule Start', type: true },
  { value: 'cloudActionRuleEnd', label: 'Cloud Rule End', type: true },
  { value: 'objectAnalyticsOn', label: 'Object Analytics On', type: true },
  { value: 'objectAnalyticsOff', label: 'Object Analytics Off', type: true }
];

export const filterCategory = (inputTypes) => {
  let category = EVENTS.find((e) => e.id == 'category')
    ['options'].filter((n) => n.category && inputTypes.includes(n.value))
    .map((i) => i.value);

  let types = EVENTS.filter(
    (n) => !n.category && inputTypes.includes(n.value)
  ).map((i) => i.value);

  return { category, types };
};

const EventIcon = styled('span')`
  color: var(--neutral-00);
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  background: ${(props) => props.background || 'var(--secundary-v-02)'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.color || '#267DFF'};
    width: 16px;
    height: 16px;
  }
`;

export const Option = ({ children, ...props }) => {
  if (props.data.level)
    return (
      <components.Option {...props}>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 0.5rem;
          `}
        >
          <div
            data-level={props.data.level}
            css={css`
              height: 12px;
              width: 12px;
              &[data-level='info'] {
                background: var(--neutral-05);
              }
              &[data-level='warning'] {
                background: var(--orange-base);
              }
              &[data-level='critical'] {
                background: var(--red-base);
              }

              border-radius: 50%;
              display: inline-block;
            `}
          ></div>

          <span className="label">{props.label}</span>
        </div>
      </components.Option>
    );
  else
    return (
      <components.Option {...props}>
        <div
          css={css`
            height: 48px;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 1rem;
            font-size: 14px;
            padding: 0.5rem;
            .description {
              color: var(--neutral-01);
              font-size: 12px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          `}
        >
          {EVENTS_CONSTANTS.colors[props.value] && (
            <EventIcon
              color={EVENTS_CONSTANTS.colors[props.value]}
              background={EVENTS_CONSTANTS.backgroundColors[props.value]}
            >
              {typeof EVENTS_CONSTANTS.icons[props.value] == 'function' &&
                EVENTS_CONSTANTS.icons[props.value]()}
            </EventIcon>
          )}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              min-width: 0;
              > span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis ellipsis;
              }
            `}
          >
            <span className="label">{props.label}</span>
            <span className="description" title={props.data.description}>
              {props.data.description}
            </span>
          </div>
        </div>
      </components.Option>
    );
};
const EventSelect = ({
  field = {},
  form = {},
  category = false,
  level = false,
  reason = false,
  types,

  ...props
}) => {
  let options = EVENTS;

  if (!category) options = EVENTS.filter((i) => i.id != 'category');
  if (!level) options = options.filter((i) => i.id != 'level');
  if (!reason) options = options.filter((i) => i.id != 'reason');

  if (Array.isArray(types)) {
    options = EVENTS.filter(
      (i) => types.includes(i.value) || types.includes(i.id)
    );
  }

  const [value, setValue] = React.useState([
    ...EVENTS.filter((i) => form.values?.[field.name]?.includes(i.value)),
    ...EVENTS.find((e) => e.id == 'category')['options'].filter((i) =>
      form.values?.[field.name]?.includes(i.value)
    )
  ]);

  const isOptionDisabled = (option, selectValue) => {
    if (selectValue?.length >= 4) return true;
    if (option.category) {
      if (selectValue?.some((i) => !i.category)) return true;
    } else if (!option.category)
      if (selectValue?.some((i) => i.category)) return true;
    return false;
  };

  useFormValues(
    value,
    'value',
    field?.name,
    form.setFieldValue,
    props.isMulti || true
  );

  return (
    <SelectWrapper
      css={css`
        .select__menu-list {
          max-height: 30vh !important;
        }
      `}
      menuPlacement="auto"
      placeholder="Event..."
      isOptionDisabled={isOptionDisabled}
      value={value}
      onChange={setValue}
      itemComponents={{ Option, Group, GroupHeading, IndicatorSeparator: null }}
      isMulti={true}
      options={options}
      idx="value"
      label="label"
      field={field}
      form={form}
      {...props}
    />
  );
};

export default EventSelect;
